<template>
  <Content :search="false" v-if="role">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
      </div>
    </template>
    
    <template v-slot:content>
      <div>
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <RoleDetails :role="role" />
            <!-- <ProductionApplicationDetails :productionApplication="productionApplication" /> -->
          </ul>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import RoleDetails from "@/components/Role/RoleDetails"

export default {
  name: "Role",
  components: {
    RoleDetails,
  },
  created() {
    this.$store.dispatch('getSingleRole', this.$route.params.roleId)
  },
  computed: {
    ...mapGetters({
      role: 'role',
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style>

</style>