<template>
  <li>
    <item-card>
      <template v-slot:title>
        <template v-if="!displayForm('editRole')">
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            {{ role.display_name }}
          </h3>
        </template>
        <template v-else>
          <form class="space-y-6 w-full">

            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div class="col-span-1">
                <Input name="display_name" placeholder="Nosaukums" v-model="form.display_name" :errors="errors.display_name ? errors.display_name : errorMessages.display_name ? errorMessages.display_name : []" />
              </div>
            </div>

            <div>
              <div class="flex justify-end">
                <button @click="cancelEditRole" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                  Atcelt
                </button>

                <template v-if="!loading">
                  <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="editRole">
                    Saglabāt
                  </button>
                </template>
                <template v-else>
                  <Loading />
                </template>
              </div>
            </div>

          </form>
        </template>
      </template>
      <template v-slot:buttons>

        <template v-if="!displayForm('editRole')">
          <template v-if="role.users.length > 0">
            <Button :icon="showUser ? 'account-multiple-minus' : 'account-multiple-plus'" @click="showUsers = !showUsers" />
          </template>

          <template v-if="!displayForm('editForm')">
            <Button icon="pencil" @click="showForm('editRole')" />
          </template>
        </template>

      </template>

      <template v-slot:content>
        
        <!-- Show users -->
        <template v-if="showUsers">
          <template v-for="(user, index) in role.users" :key="index">
            <router-link :to="`/users/edit/${user.id}`" class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full px-2 rounded-lg">
              <div class="py-4">
                <div class="sm:flex flex-wrap lg:flex-nowrap items-center">
                  <div class="grid grid-cols-12 w-full">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                      <ItemText title="Lietotājs" :text="user.name" />
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                      <ItemText title="E-pasts" :text="user.email" />
                    </div>
                  </div>

                  <div class="ml-auto">
                    <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
                      <button class="text-gray-400 flex items-center">
                        Skatīt
                        <ChevronRightIcon class="w-6 h-6"/>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </template>

        <!-- Show role permissions -->
        <template v-if="role.permissions.length > 0">
          <div class="w-full mb-4">
            <h2 class="font-semibold dark:text-gray-300">Atļaujas</h2>
          </div>

          <div class="flex flex-wrap">
            <template v-for="(permission, index) in role.permissions" :key="index">
              <Badge :text="permission.display_name" class="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-300 mr-2 mb-2" :button="true" :onClick="() => removePermission(permission.id)" />
            </template>
          </div>
        </template>

        <!-- Add permissions -->
        <template v-if="role.available_permissions.length > 0">
          <div class="w-full mt-6 flex items-center">
            <h2 class="font-semibold dark:text-gray-300">Pievienot papildus atļaujas</h2>
            <Button :icon="showAddPermissions ? 'close' : 'plus'" @click="showAddPermissions = !showAddPermissions" />
          </div>

          <div class="w-full">
            <template v-if="showAddPermissions">
              <div class="flex flex-wrap items-end">
                <div class="max-w-screen-sm flex-1">
                  <Autocomplete placeholder="Mekēt atļaujas" v-model="selectedPermissions" itemName="display_name"  :items="role.available_permissions" :showItems="true" :top="true" :multiple="true" />
                </div>

                <div class="ml-auto">
                  <div class="flex justify-end">
                    <button @click="cancelAddPermissions" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                      Atcelt
                    </button>

                    <template v-if="selectedPermissions.length > 0">
                      <template v-if="!loading">
                        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="addPermissions">
                          Pievienot
                        </button>
                      </template>
                      <template v-else>
                        <Loading />
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>

        </template>
      </template>
    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"
import Badge from "@/components/Components/Badge"
import Autocomplete from "@/components/Components/Autocomplete"
import Loading from "@/components/Components/Loading"
import Validation from "@/modules/Validation";

import {
  ChevronRightIcon,
} from "@heroicons/vue/solid"

export default {
  name: "RoleDetails",
  components: {
    ItemCard,
    ItemText,
    Input,
    Badge,
    Autocomplete,
    Loading,
    ChevronRightIcon,
  },
  props: ["role"],
  data() {
    return {
      showUsers: false,
      showAddPermissions: false,
      selectedPermissions: [],
      form: null,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
      loading: 'loading',
      errorMessages: 'roleErrorMessages',
      errors: "errors",
    }),
    formValidation() {
      return {
        display_name: {
          rules: ['required']
        },
      }
    }
  },
  mounted() {
    this.form = this.role
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('clearRoleErrorMessages')
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    viewUser(userId) {
      this.$router.push({ path: '/users/edit/' + userId })
    },
    cancelAddPermissions() {
      this.selectedPermissions = []
      this.showAddPermissions = false
    },
    addPermissions() {
      const selectedPermissions = this.selectedPermissions.map(permission => permission.id)
      this.$Progress.start()
      this.$store.dispatch('addRolePermissions', {
        permissions: selectedPermissions,
        roleId: this.role.id
      })
      this.selectedPermissions = []
      this.showAddPermissions = false
    },
    removePermission(permissionId) {
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteRolePermission', {
          permissionId: permissionId,
          roleId: this.role.id
        })
        this.selectedPermissions = []
        this.showAddPermissions = false
      }
    },
    cancelEditRole() {
      this.$store.dispatch('removeAllFormsForDisplay')
      this.$store.dispatch('getSingleRole', this.$route.params.roleId)
    },
    editRole() {
      this.$Progress.start()
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateRole', this.form)
      } else this.$Progress.fail()
    }
  }
}
</script>

<style>

</style>